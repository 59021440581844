// expose external react and react dom libs on the window for chunks to be able to import them before they are loaded

// @ts-ignore
window.reactDOMReference = window.ReactDOM = { loading: true }
window.reactDOMLoadedPromise = new Promise(function (r) {
	window.resolveReactDOMLoadedPromise = r
})
window.onReactDOMLoaded = function () {
	// if a chunk that is dependent on react-dom was evaluated prior to evaluating react-dom,
	// we need to override the stale reactDOMReference this chunk holds
	// with window.ReactDOM which is overridden by react-dom evaluation.
	Object.assign(window.reactDOMReference, window.ReactDOM)
	window.resolveReactDOMLoadedPromise()
}

// @ts-ignore
window.reactReference = window.React = { loading: true }
window.reactLoadedPromise = Promise.all([
	new Promise(function (r) {
		window.resolveReactLoadedPromise = r
	}),
	window.reactDOMLoadedPromise,
])
window.onReactLoaded = function () {
	// if a chunk that is dependent on react was evaluated prior to evaluating react,
	// we need to override the stale reactReference this chunk holds
	// with window.React which is overridden by react evaluation.
	Object.assign(window.reactReference, window.React)
	window.resolveReactLoadedPromise()
}
